<template>
  <div class="">
    <div class="flex justify-between items-center mb-6">
      <div>
        <!-- <button
          @click="exportToPDF()"
          class="
            px-4
            py-2
            mb-4
            bg-gray-50
            border
            rounded-md
            text-balck
            items-center
            flex
          "
        >
          <i class="bx bxs-file-pdf"></i> Download PDF
        </button> -->
        <!-- <h1 class="text-4xl">Sales report</h1> -->
        <!-- <p class="text-sm text-gray-400">Welcome to your dashboard</p> -->
      </div>
    </div>

    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow p-5 overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <div id="element-to-print">
              <template>
                <AgentTransactionsReports :data="$route.params.result" />
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import html2pdf from "html2pdf.js";

import AgentTransactionsReports from "./Docs/AgentTransactionsReports.vue";

export default {
  components: {
    AgentTransactionsReports,
  },

  // middleware: "auth",
  layout: "default",

  data: () => ({
    branches: null,

    reportOn: "",
    from: "",
    to: "",
  }),

  created() {},

  methods: {
    getFileName() {
      return this.$route.params.from + "-" + this.$route.params.to;
    },

    exportToPDF() {
      this.isLoading = true;
      var element = document.getElementById("element-to-print");
      var opt = {
        margin: 0,
        filename: `${this.getFileName()}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 1 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };

      // New Promise-based usage:
      html2pdf().from(element).set(opt).save();

      // Old monolithic-style usage:
      html2pdf(element, opt);

      this.isLoading = false;
    },

    async generate() {
      this.isLoading = true;

      try {
        const { data } = await axios.get(
          `/agents/myreport?from=${this.from}&to=${this.to}`
        );

        this.report = data;
        this.isLoading = false;

        return true;
      } catch (e) {
        console.log("Error");
        this.isLoading = false;

        return false;
      }
    },
  },
};
</script>
