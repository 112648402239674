<template>
  <div>
    <div class="text-right">
      <button
        @click="handleDownload()"
        class="px-4 py-2 mb-4 bg-gray-50 border rounded-md text-balck flex"
      >
        <img src="../../../assets/excel.png" alt="" />
        <span class="mx-3">Download</span>
      </button>
    </div>

    <div class="flex text-lg mb-4">
      <h1 class="pr-4 border-r uppercase font-bold">
        <!-- {{ $route.params.reportOn.replace(/_/g, " ") }} -->
      </h1>
      <h1 class="px-4 border-r">
        From:
        <span class="font-bold">{{
          $route.params.from | moment("ddd, MMM Do YYYY")
        }}</span>
      </h1>
      <h1 class="px-4">
        To:
        <span class="font-bold">{{ $route.params.to | moment("ddd, MMM Do YYYY") }}</span>
      </h1>
    </div>
    <table class="min-w-full divide-y divide-gray-200">
      <thead class="bg-gray-50">
        <tr>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Date
          </th>

          <th
            scope="col"
            class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
           Created By
          </th>

          <th
            scope="col"
            class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Total Payment
          </th>
          
          <th
            scope="col"
            class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
             Fee
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Commission
          </th>

          <th
          scope="col"
          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
        >
          Total Transactions #
        </th>

      
        </tr>
      </thead>

      <tbody class="bg-white divide-y divide-gray-200">
        <tr v-for="(transaction, i) in data.agent_sales" :key="i">
          <td class="px-6 py-4 whitespace-nowrap">
            <div class="flex items-center">
              <div class="ml-4">
                <div class="text-sm font-medium text-gray-900">
                  {{ transaction?.transaction_date }}
                  <!-- <span class="text-xs text-gray-600">{{ transaction.agent_id }}</span> -->
                </div>
              </div>
            </div>
          </td>

          <td class="px-6 py-4 whitespace-nowrap text-center">
            <div class="text-sm text-gray-900">
              
              <b>{{ transaction?.agent_name }}</b>
            </div>
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-center">
            <div class="text-sm text-gray-900">
              GMD
              <b>{{ formatPrice(transaction.total_payment) }}</b>
            </div>
          </td>
          <td class="px-6 py-4 whitespace-nowrap">
            <div class="text-sm text-gray-900 text-center">
              GMD  {{  formatPrice(transaction.fee) }}
            </div>
          </td>
          <td class="px-6 py-4 whitespace-nowrap">
            <div class="text-sm text-gray-900">
              GMD <b>{{ formatPrice(transaction.commission) }}</b>
            </div>
          </td>

          

       
          <!-- <td class="px-6 py-4 whitespace-nowrap">
            <div class="text-sm text-gray-900">
             
            </div>
          </td> -->
        </tr>

        <tr class="bg-gray-50">
          <td class="px-6 py-4 whitespace-nowrap">
            <div class="flex items-center">
              <div class="ml-4">
                <div class="text-sm font-medium text-gray-900">
                  <b>TOTAL</b>
                </div>
              </div>
            </div>
          </td>
          <td class="px-6 py-4 whitespace-nowrap"></td>

          <td class="px-6 py-4 whitespace-nowrap">
            <div class="text-sm text-gray-900 text-center">
              GMD <b>{{ formatPrice(data?.total_sales) }}</b>
            </div>
          </td>
          <td class="px-6 py-4 whitespace-nowrap">
            <div class="text-sm text-gray-900 text-center">
              GMD <b>{{ formatPrice(data?.total_fees) }}</b>
            </div>
          </td>

          <td class="px-6 py-4 whitespace-nowrap">
            <div class="text-sm text-gray-900">
              GMD <b>{{ formatPrice(data?.total_commission) }}</b>
            </div>
          </td>
          <td class="px-6 py-4 whitespace-nowrap">
            <div class="text-sm text-gray-900 text-center">
               <b>{{ data?.overall_transactions }}</b>
            </div>
          </td>
        </tr>

        <!-- More people... -->
      </tbody>
    </table>
  </div>
</template>

<script>
import * as XLSX from 'xlsx';
// import axios from 'axios';
export default {
  props: ["data"],

  data: () => ({
    downloadLoading: false,

    filename: "",
    autoWidth: true,
    bookType: "xlsx",
  }),

  computed: {
  
  
  },

  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    getFileName() {
      return this.$route.params.from + "-" + this.$route.params.to;
    },
   
  handleDownload() {
    this.downloadLoading = true;

    const filterVal = ["transaction_date", "agent_name", "total_payment", "fee", "commission", "transaction_count"];
    const data = this.data.agent_sales.map((transaction) => ({
      transaction_date: transaction.transaction_date,
      agent_name: transaction.agent_name,
      total_payment: this.formatPrice(transaction.total_payment),
      fee: this.formatPrice(transaction.fee),
      commission: this.formatPrice(transaction.commission),
    }));

    const totals = {
      transaction_date: 'TOTAL(GMD)',
      agent_name: '',
      total_payment: this.formatPrice(this.data.total_sales),
      fee: this.formatPrice(this.data.total_fees),
      commission: this.formatPrice(this.data.total_commission),
      transaction_count: this.data.overall_transactions,
    };
    data.push(totals);

    const ws = XLSX.utils.json_to_sheet(data, { header: filterVal });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

    // Write to file
    XLSX.writeFile(wb, `${this.getFileName()}.xlsx`);

    this.downloadLoading = false;
  },






  },
};
</script>
